export const useLocalSettings = defineStore('settings', {
  state: () => ({
    theme: null as 'light'|'dark'|null,
    redactPassword: true,
    redactLogins: false,
    redactPlatforms: false,
  }),
  persist: {
    storage: localStorage,
  }
});
