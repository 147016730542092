import payload_plugin_qLmFnukI99 from "/app/frontend/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "/app/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/app/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/app/frontend/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PSbK9A48ZQ from "/app/frontend/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_kyYn5AnnAK from "/app/frontend/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_WLsn00x1qh from "/app/frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_e0FkfVuy48 from "/app/frontend/src/plugins/auth.ts";
import toast_ammHSktxCk from "/app/frontend/src/plugins/toast.ts";
import vuetify_nuxt_plugin_client_moE2XX1Cig from "/app/frontend/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  plugin_PSbK9A48ZQ,
  vuetify_no_client_hints_kyYn5AnnAK,
  plugin_WLsn00x1qh,
  auth_e0FkfVuy48,
  toast_ammHSktxCk,
  vuetify_nuxt_plugin_client_moE2XX1Cig
]