import type { NavigateToOptions } from '#app/composables/router'
import type { LocationQueryValue } from "#vue-router";
import type { UserSelf } from "@/utils/types";

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as UserSelf | null,
    authRedirect: null as string | null,
  }),
  actions: {
    setAuthRedirect(redirect?: LocationQueryValue|LocationQueryValue[]) {
      if (Array.isArray(redirect)) {
        redirect = redirect[0];
      }

      if (redirect && redirect.startsWith('/')) {
        this.authRedirect = redirect;
      }
    },
    clearAuthRedirect() {
      this.authRedirect = null;
    }
  },
  persist: {
    storage: sessionStorage,
    pick: ['authRedirect'],
  }
})

export function useAuth() {
  const store = useAuthStore();
  const user = computed(() => store.user);
  const loggedIn = computed(() => !!store.user);

  async function fetchUser() {
    try {
      store.user = await $fetch<UserSelf>('/api/v1/users/self/', {
        method: 'GET',
      });
    } catch {
      store.user = null;
    }
    return store.user;
  }

  async function redirect(to?: LocationQueryValue|LocationQueryValue[]) {
    let redirect = store.authRedirect || '/';
    store.authRedirect = null;
    if (Array.isArray(to) && to[0]) {
      redirect = to[0];
    } else if (to && typeof to === 'string') {
      redirect = to;
    }
    if (!redirect.startsWith('/')) {
      redirect = '/';
    }

    const external = ['/api', '/admin', '/static'].some(p => redirect.startsWith(p));
    return await navigateTo(redirect, { external });
  }

  async function redirectToReAuth(options?: NavigateToOptions) {
    const route = useRoute();
    store.setAuthRedirect(route.fullPath);
    return await navigateTo('/login/reauth/', options);
  }

  async function logout() {
    try {
      await $fetch('/api/v1/auth/logout/', {
        method: 'POST',
        body: {}, // Send request as JSON to prevent CSRF errors
      });
    } catch {
      // Ignore errors
    }
    await navigateTo('/login/');
    store.user = null;
  }

  return {
    loggedIn,
    user,
    store,
    logout,
    redirect,
    redirectToReAuth,
    fetchUser,
  };
}
