
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqsWUl9KSUYDCMeta } from "/app/frontend/src/pages/(landingpage)/faqs.vue?macro=true";
import { default as index0kJYkwrF7qMeta } from "/app/frontend/src/pages/(landingpage)/index.vue?macro=true";
import { default as pricingLobSIAFj9wMeta } from "/app/frontend/src/pages/(landingpage)/pricing.vue?macro=true";
import { default as indexp4AWOeS8pnMeta } from "/app/frontend/src/pages/(landingpage)/signup/index.vue?macro=true";
import { default as terms_45of_45service_45freeDqY480ZFlFMeta } from "/app/frontend/src/pages/(landingpage)/signup/terms-of-service-free.vue?macro=true";
import { default as data_45privacy15gZNCtUQ4Meta } from "/app/frontend/src/pages/data-privacy.vue?macro=true";
import { default as indexUJE66jrOkAMeta } from "/app/frontend/src/pages/domains/index.vue?macro=true";
import { default as get_45startedpZAswLQcd2Meta } from "/app/frontend/src/pages/get-started.vue?macro=true";
import { default as accountseqNMoZ6TJPMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/accounts.vue?macro=true";
import { default as _91alertId_93gelLoUXV8fMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/[alertId].vue?macro=true";
import { default as indexxNICOx2CwpMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/index.vue?macro=true";
import { default as unsubscribe4BZIPoVdAEMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/unsubscribe.vue?macro=true";
import { default as indexKiA3ILPgUoMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/index.vue?macro=true";
import { default as reportsqCyN4DTq2gMeta } from "/app/frontend/src/pages/leakprofiles/[leakprofileId]/reports.vue?macro=true";
import { default as indexRpq5DzZDb9Meta } from "/app/frontend/src/pages/leakprofiles/index.vue?macro=true";
import { default as new0AyanxheOpMeta } from "/app/frontend/src/pages/leakprofiles/new.vue?macro=true";
import { default as forgot_45passwordtPl2zd0dOWMeta } from "/app/frontend/src/pages/login/forgot-password.vue?macro=true";
import { default as index6tsFOSXP2dMeta } from "/app/frontend/src/pages/login/index.vue?macro=true";
import { default as reauthnwza5tMdRrMeta } from "/app/frontend/src/pages/login/reauth.vue?macro=true";
import { default as _91domainId_93vZGhICPcaVMeta } from "/app/frontend/src/pages/lookup/[domainId].vue?macro=true";
import { default as indexIlyOjkh9UqMeta } from "/app/frontend/src/pages/lookup/index.vue?macro=true";
import { default as orders_45f4c65d09DEIyfKJS5SMeta } from "/app/frontend/src/pages/orders-f4c65d09.vue?macro=true";
import { default as indext9a7DYRpPHMeta } from "/app/frontend/src/pages/users/index.vue?macro=true";
import { default as apitokensNzrX0HbFb7Meta } from "/app/frontend/src/pages/users/self/apitokens.vue?macro=true";
import { default as indexqsOtAAd0dxMeta } from "/app/frontend/src/pages/users/self/index.vue?macro=true";
import { default as securityFhHzVi7ffMMeta } from "/app/frontend/src/pages/users/self/security.vue?macro=true";
import { default as selfAUDD3dmxkeMeta } from "/app/frontend/src/pages/users/self.vue?macro=true";
import { default as set_45password4emSIKEGWCMeta } from "/app/frontend/src/pages/users/set-password.vue?macro=true";
export default [
  {
    name: "faqs",
    path: "/faqs",
    meta: faqsWUl9KSUYDCMeta || {},
    component: () => import("/app/frontend/src/pages/(landingpage)/faqs.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index0kJYkwrF7qMeta || {},
    component: () => import("/app/frontend/src/pages/(landingpage)/index.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingLobSIAFj9wMeta || {},
    component: () => import("/app/frontend/src/pages/(landingpage)/pricing.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexp4AWOeS8pnMeta || {},
    component: () => import("/app/frontend/src/pages/(landingpage)/signup/index.vue")
  },
  {
    name: "signup-terms-of-service-free",
    path: "/signup/terms-of-service-free",
    meta: terms_45of_45service_45freeDqY480ZFlFMeta || {},
    component: () => import("/app/frontend/src/pages/(landingpage)/signup/terms-of-service-free.vue")
  },
  {
    name: "data-privacy",
    path: "/data-privacy",
    meta: data_45privacy15gZNCtUQ4Meta || {},
    component: () => import("/app/frontend/src/pages/data-privacy.vue")
  },
  {
    name: "domains",
    path: "/domains",
    meta: indexUJE66jrOkAMeta || {},
    component: () => import("/app/frontend/src/pages/domains/index.vue")
  },
  {
    name: "get-started",
    path: "/get-started",
    component: () => import("/app/frontend/src/pages/get-started.vue")
  },
  {
    name: "leakprofiles-leakprofileId-accounts",
    path: "/leakprofiles/:leakprofileId()/accounts",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/accounts.vue")
  },
  {
    name: "leakprofiles-leakprofileId-alerts-alertId",
    path: "/leakprofiles/:leakprofileId()/alerts/:alertId()",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/[alertId].vue")
  },
  {
    name: "leakprofiles-leakprofileId-alerts",
    path: "/leakprofiles/:leakprofileId()/alerts",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/index.vue")
  },
  {
    name: "leakprofiles-leakprofileId-alerts-unsubscribe",
    path: "/leakprofiles/:leakprofileId()/alerts/unsubscribe",
    meta: unsubscribe4BZIPoVdAEMeta || {},
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/alerts/unsubscribe.vue")
  },
  {
    name: "leakprofiles-leakprofileId",
    path: "/leakprofiles/:leakprofileId()",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/index.vue")
  },
  {
    name: "leakprofiles-leakprofileId-reports",
    path: "/leakprofiles/:leakprofileId()/reports",
    component: () => import("/app/frontend/src/pages/leakprofiles/[leakprofileId]/reports.vue")
  },
  {
    name: "leakprofiles",
    path: "/leakprofiles",
    component: () => import("/app/frontend/src/pages/leakprofiles/index.vue")
  },
  {
    name: "leakprofiles-new",
    path: "/leakprofiles/new",
    component: () => import("/app/frontend/src/pages/leakprofiles/new.vue")
  },
  {
    name: "login-forgot-password",
    path: "/login/forgot-password",
    meta: forgot_45passwordtPl2zd0dOWMeta || {},
    component: () => import("/app/frontend/src/pages/login/forgot-password.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: index6tsFOSXP2dMeta || {},
    component: () => import("/app/frontend/src/pages/login/index.vue")
  },
  {
    name: "login-reauth",
    path: "/login/reauth",
    meta: reauthnwza5tMdRrMeta || {},
    component: () => import("/app/frontend/src/pages/login/reauth.vue")
  },
  {
    name: "lookup-domainId",
    path: "/lookup/:domainId()",
    component: () => import("/app/frontend/src/pages/lookup/[domainId].vue")
  },
  {
    name: "lookup",
    path: "/lookup",
    component: () => import("/app/frontend/src/pages/lookup/index.vue")
  },
  {
    name: "orders-f4c65d09",
    path: "/orders-f4c65d09",
    component: () => import("/app/frontend/src/pages/orders-f4c65d09.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indext9a7DYRpPHMeta || {},
    component: () => import("/app/frontend/src/pages/users/index.vue")
  },
  {
    name: selfAUDD3dmxkeMeta?.name,
    path: "/users/self",
    component: () => import("/app/frontend/src/pages/users/self.vue"),
    children: [
  {
    name: "users-self-apitokens",
    path: "apitokens",
    component: () => import("/app/frontend/src/pages/users/self/apitokens.vue")
  },
  {
    name: "users-self",
    path: "",
    component: () => import("/app/frontend/src/pages/users/self/index.vue")
  },
  {
    name: "users-self-security",
    path: "security",
    component: () => import("/app/frontend/src/pages/users/self/security.vue")
  }
]
  },
  {
    name: "users-set-password",
    path: "/users/set-password",
    meta: set_45password4emSIKEGWCMeta || {},
    component: () => import("/app/frontend/src/pages/users/set-password.vue")
  }
]